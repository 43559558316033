import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'Badge',
  props: {
		color: {
			type: String,
			default: 'hub',
		},
	},
  setup(__props) {

	const colorClass: { [key: string]: string } = {
		ph: 'bg-notification text-white',
		hub: 'bg-notification text-white',
	};

	const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([colorClass[props.color], "relative flex h-7 w-7 items-center justify-center overflow-hidden rounded-full text-sm font-bold"])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})